
import { defineComponent, ref } from 'vue'
import WizardWhiteBlock from '@/components/pages/auth/wizard/WizardWhiteBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WizardFormLine from '@/components/pages/auth/wizard/WizardFormLine.vue'
import PageStep from '@/components/layout/PageStep.vue'
import type { FlagCountry } from '@/definitions/shared/types'
import { wizardSteps } from '@/definitions/auth/data'

export default defineComponent({
  components: {
    WizardWhiteBlock,
    WizardFormLine,
    TmButton,
    PageStep,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(2)

    const countriesList = ref<FlagCountry[]>([
      {
        id: 'au',
        name: 'Australia',
      },
    ])
    const numbersList = ref([
      { number: '+61 406 034 460', info: '(2-way SMS & voice calls)' },
      { number: '+61 406 134 430', info: '(SMS)' },
      { number: '+61 406 121 942', info: '(Two-way SMS & Calls)' },
    ])
    const number = ref(numbersList.value[0])
    const country = ref(countriesList.value[0])
    const yourPhone = ref()
    const describe = ref('')

    return {
      wizardSteps,
      currentIndex,
      numbersList,
      yourPhone,
      number,
      countriesList,
      describe,
      country,
    }
  },
})
